import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import bg from "../images/2017060895195232.png";

// markup
const IndexPage = () => {
  return (
    <main id="page-top">
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container px-5">
                <a className="navbar-brand" href="#page-top">
                  <StaticImage src="../images/DGCI.png" alt="DGCI Logo" width={300} height={116} />
                </a>
                <div className="phone-div float-right text-center" style={{flex:"none"}}>
                  <a style={{color:'#000',textDecoration:'none'}} href="tel:(203) 980-7675">(203) 980-7675</a>
                </div>
                {/*<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><a className="nav-link" href="#!">Sign Up</a></li>
                        <li className="nav-item"><a className="nav-link" href="#!">Log In</a></li>
                    </ul>
                </div>*/}
            </div>
        </nav>

        <header className="masthead text-center text-white">
            <div className="masthead-content">
                <div className="container px-5" style={{minHeight: 200}}>
                   {/*} <h1 className="masthead-heading mb-0">One Page Wonder</h1>
                    <h2 className="masthead-subheading mb-0">Will Rock Your Socks Off</h2>
                    <a className="btn btn-primary btn-xl rounded-pill mt-5" href="#scroll">Learn More</a>*/}
                </div>
            </div>

        </header>
        <footer class="py-5 bg-black">
            <div class="container px-5"><p class="m-0 text-center text-white small">Copyright &copy; Double Green Circle Inc</p></div>
        </footer>
    </main>
  )
}

export default IndexPage
